/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Popover,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  getCardNavigationUrl,
  getTypeFromVersion,
} from 'src/util/CardDisplayUtils';
import {
  cloudLight,
  cloud,
  dusk,
  eggshell,
  heather,
  light,
  midnight,
  seaglass,
  seaglassDarker,
  white,
  blueglass,
  newBlueglass,
} from 'src/constants/colors';
import EditIcon from '@mui/icons-material/Edit';
import { CalloutTooltip } from '@components/alertManager/CalloutTooltip';
import {
  FusionEventData,
  OpenUserRegistration,
  TemplateType,
  useFusionEventsQuery,
  useGetOpenUserRegistrationsQuery,
  useUpdateOpenUserRegistrationMutation,
} from 'src/services/gql/generated';
import { SubscriptionCardConfigItemV1 } from '@components/cardConfig/common/CardConfigItemV1';
import { useEnvFromQueryParam } from 'src/hooks/useEnvFromQueryParam';
import { useGetUserProfile } from '../AccountSettings/AccountSettingsDataContext';
import { chains } from '@components/cardConfig/form/CardConfigSelectChain';
import { ReactComponent as IconEmail } from '../../assets/icn-email.svg';
import { ReactComponent as IconDiscord } from '../../assets/icn-discord.svg';
import { ReactComponent as IconSms } from '../../assets/icn-sms.svg';
import { ReactComponent as IconAlertHistory } from '../../assets/icn-alert-history.svg';
import { ReactComponent as IconTelegram } from '../../assets/icn-telegram.svg';
import { ReactComponent as IconSlack } from '../../assets/icn-slack.svg';
import { ReactComponent as WalletIcon } from '../../assets/icn-wallet.svg';
import { ReactComponent as BrowserIcon } from '../../assets/icn-browser.svg';
import { ContentCopy } from '@mui/icons-material';
import { useFeatureFlag } from '@components/auth/FeatureFlagContext';
import useClickOutside from 'src/hooks/useClickOutside';
import { useGetListTemplates } from '@components/alertManager/TopicsDataContext';
import { ReactComponent as ErrorIcon } from '../../assets/error-icon.svg';
import { PrimaryButton } from '@components/buttons/PrimaryButton';
import DefineTemplatesDialog from '@components/alertManager/DefineTemplatesDialog';
import { ConfirmDialog } from '@components/cardConfig/ConfirmDialog';
import CreateUIConfigCard from '@components/alertManager/CreateUIConfigCard';
import cardHeaderImage from '../../assets/notificationCard.png';
import easySubscribeBannner from '../../assets/easy_subscribe_banner.png';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReactComponent as AnnouncementIcon } from '../../assets/blueglass-announcement.svg';
import { ReactComponent as TelegramIcon } from '../../assets/bluglass-telegram.svg';
import CustomDatePicker from '@components/input/CustomDatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { GetOpenUserRegistrationsQuery } from 'src/services/gql/queries/GetOpenUserRegistrationsQuery.gql';
import AddTelegramToGroupDialog from '@components/alertManager/AddTelegramToGroupDialog';
import { getUserTimeZone } from 'src/util/dateUtils';
dayjs.extend(utc);

const convertDestinationToTemplateType = (name: string): TemplateType => {
  name = name.toUpperCase();
  switch (name) {
    case 'ALERT HISTORY':
      return TemplateType.EVENT_DETAIL_BODY;
    case 'EMAIL':
      return TemplateType.EMAIL;
    case 'TELEGRAM':
      return TemplateType.TELEGRAM;
    case 'SMS':
      return TemplateType.SMS;
    case 'DISCORD':
      return TemplateType.DISCORD;
    case 'SLACK':
      return TemplateType.SLACK_CHANNEL;
    case 'WALLET':
      return TemplateType.WEB3;
    default:
      return TemplateType.EMAIL_SUBJECT;
  }
};
export type CustomFusionEventData = Omit<
  FusionEventData,
  'tenantId' | 'hasAlertFilter'
>;

type Props = {
  topicsData: CustomFusionEventData[];
  cardConfigData: SubscriptionCardConfigItemV1[];
  isCreatingNotificationCard: boolean;
  handleCreateEasySubsConfig: () => void;
  handleCreateNotificationCardConfig: () => void;
};

type UploadedTemplatesByTopicsType = {
  topicId: string;
  templates: TemplateType[];
};

const formatSelectedDate = (selectedDate?: string) => {
  if (selectedDate) {
    return dayjs(selectedDate).utc().format('YYYY-MM-DD HH:mm:ss');
  }
  return dayjs().utc().format('YYYY-MM-DDTHH:mm:ss');
};

const isOpenUserRegistration = (item: any): item is OpenUserRegistration => {
  return item.__typename === 'OpenUserRegistration';
};

const isCustomFusionEventData = (item: any): item is CustomFusionEventData => {
  return item.__typename === 'CustomFusionEventData';
};

const UIConfigTab: React.FC<Props> = ({
  topicsData,
  cardConfigData,
  isCreatingNotificationCard,
  handleCreateEasySubsConfig,
  handleCreateNotificationCardConfig,
}: Props) => {
  const { isAP2Enabled } = useFeatureFlag();
  const navigate = useNavigate();
  const addUICardTooltipRef = useRef<HTMLDivElement>(null);
  const implementUICardTooltipRef = useRef<HTMLDivElement>(null);
  const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(true);
  const [isCopyCodeModalOpen, setIsCopyCodeModalOpen] =
    React.useState<boolean>(false);
  const [cardCode, setCardCode] = React.useState<string>('');
  const hasSeenImplementUICardTooltip = localStorage.getItem(
    'hasSeenImplementUICardTooltip',
  );
  const env = useEnvFromQueryParam();
  const { data } = useGetUserProfile();

  const { refetchListTemplates: fetchTopicTemplates } = useGetListTemplates('');
  const [uploadedTemplatesByTopics, setUploadedTemplatesByTopics] =
    React.useState<UploadedTemplatesByTopicsType[]>([]);
  const [cardId, setCardId] = React.useState('');
  const [cardURL, setCardURL] = React.useState('');
  const [anchorElEnd, setAnchorElEnd] = React.useState<HTMLElement | null>(
    null,
  );
  const [endDate, setEndDate] = React.useState('');
  const [isEndPopoverOpen, setIsEndPopoverOpen] = React.useState<{
    campaignId: string;
    isVisible: boolean;
  }>({ isVisible: false, campaignId: '' });

  const [selectedTopic, setSelectedTopic] = React.useState<
    CustomFusionEventData | OpenUserRegistration | undefined
  >(undefined);

  const { data: easySubsCardConfigData } = useGetOpenUserRegistrationsQuery({
    variables: {},
  });
  const { data: fusionEventsData } = useFusionEventsQuery({
    variables: {
      first: 50,
    },
  });

  const [updateOpenUserRegistrationMutation] =
    useUpdateOpenUserRegistrationMutation();

  const [isLoading, setIsLoading] = React.useState(true);
  const [isAddTelegramToGroupDialogOpen, setIsAddTelegramToGroupDialogOpen] =
    React.useState<{
      open: boolean;
      link?: string;
    }>({ open: false, link: '' });

  console.log('date coming endDate', endDate);

  const handleOpenEndPopover = (
    event: React.MouseEvent<HTMLElement>,
    selectedDate: string | undefined,
    campaignId: string,
  ) => {
    setAnchorElEnd(event.currentTarget.parentElement as HTMLElement);
    setEndDate(formatSelectedDate(selectedDate));
    setIsEndPopoverOpen({ isVisible: true, campaignId });
  };

  const handleCloseEndPopover = () => {
    setAnchorElEnd(null);
    setIsEndPopoverOpen({ isVisible: false, campaignId: '' });
  };

  const getUploadedTemplates = async () => {
    setIsLoading(true);
    try {
      let topicIds =
        cardConfigData?.flatMap((v) =>
          v.eventTypes.map(
            (e: any) => (e?.fusionEventId?.value as string) || '',
          ),
        ) ?? [];
      topicIds = Array.from(new Set(topicIds));

      // Retrieves uploaded templates of all given topics
      const response = await Promise.all(
        topicIds.map((id) =>
          fetchTopicTemplates({ ListTemplatesInput: { templateName: id } }),
        ),
      ).then((res) => res.map((v) => v?.data?.listTemplates?.templates));

      // Group all uploaded templates by topic Id.
      const uploadedTemplatesByTopicsResponse = response?.map((e, i) => ({
        topicId: topicIds[i],
        templates: e.map((template) => template.templateType),
      }));

      setUploadedTemplatesByTopics(uploadedTemplatesByTopicsResponse);
    } catch (err) {
      setUploadedTemplatesByTopics([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseTemplateModal = () => {
    setIsLoading(true);
    getUploadedTemplates();
    setSelectedTopic(undefined);
  };

  const updateEasySubsUICard = async (dateTime: string) => {
    try {
      await updateOpenUserRegistrationMutation({
        variables: {
          input: {
            openUserRegistrationCampaignId: isEndPopoverOpen.campaignId,
            endTime: dateTime,
          },
        },
        refetchQueries: [
          {
            query: GetOpenUserRegistrationsQuery,
          },
        ],
      });
      handleCloseEndPopover();
    } catch (error) {
      console.error('Error occurred during mutation:', error);
    }
  };

  useEffect(() => {
    getUploadedTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardConfigData]);

  const DESTINATIONS = {
    email: {
      name: 'Email',
      icon: <IconEmail style={{ fill: `${isAP2Enabled ? heather : white}` }} />,
    },
    discord: {
      name: 'Discord',
      icon: (
        <IconDiscord style={{ fill: `${isAP2Enabled ? heather : white}` }} />
      ),
    },
    sms: {
      name: 'SMS',
      icon: <IconSms style={{ fill: `${isAP2Enabled ? heather : white}` }} />,
    },
    telegram: {
      name: 'Telegram',
      icon: (
        <IconTelegram style={{ fill: `${isAP2Enabled ? heather : white}` }} />
      ),
    },
    slack: {
      name: 'Telegram',
      icon: <IconSlack style={{ fill: `${isAP2Enabled ? heather : white}` }} />,
    },
    wallet: {
      name: 'Wallet',
      icon: (
        <WalletIcon
          style={{
            fill: `${isAP2Enabled ? heather : white}`,
            width: 22,
            height: 22,
            marginTop: -2,
          }}
        />
      ),
    },
    browser: {
      name: 'Browser',
      icon: (
        <BrowserIcon
          style={{
            width: 13,
            height: 13,
          }}
        />
      ),
    },
  };

  useClickOutside(addUICardTooltipRef, () => {
    setIsTooltipOpen(false);
    localStorage.setItem('hasSeenAddUICardTooltip', JSON.stringify(true));
    localStorage.setItem('hasSeenConfigUICardTooltip', JSON.stringify(true));
  });

  useClickOutside(implementUICardTooltipRef, () => {
    setIsTooltipOpen(false);
    localStorage.setItem('hasSeenImplementUICardTooltip', JSON.stringify(true));
  });

  if (isLoading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: '200px',
          width: '100%',
        }}
      >
        <CircularProgress size="50px" thickness={2} />
      </Box>
    );

  if (
    easySubsCardConfigData?.openUserRegistrations?.nodes?.length === 0 &&
    cardConfigData?.length === 0
  ) {
    return (
      <>
        <Stack direction={'row'} columnGap={4}>
          <CreateUIConfigCard
            title={'Notification Card'}
            headImage={cardHeaderImage}
            supportDetails={[
              {
                text: 'All destinations are supported',
                icon: (
                  <Stack sx={{ mr: 0.3, justifySelf: 'center' }}>
                    <TelegramIcon />
                  </Stack>
                ),
              },
              {
                text: 'All topics (including on-chain) are supported',
                icon: (
                  <Stack sx={{ ml: 0.3, justifySelf: 'center' }}>
                    <AnnouncementIcon />
                  </Stack>
                ),
              },
            ]}
            learnMoreLink="https://docs.notifi.network/docs/next/alert-subscribe/react-card/create-card-id"
            description={
              'In the Notification Card users can select which destinations and topics they are interested in, see their Notification History, &amp; manage their preferences.'
            }
            handleNextClick={handleCreateNotificationCardConfig}
            isCreatingNotificationCard={isCreatingNotificationCard}
          />
          <CreateUIConfigCard
            title={'Easy Subscribe'}
            headImage={easySubscribeBannner}
            supportDetails={[
              {
                text: 'Email & Telegram (Discord coming soon!)',
                icon: (
                  <Stack sx={{ mr: 0.3, justifySelf: 'center' }}>
                    <TelegramIcon />
                  </Stack>
                ),
              },
              {
                text: 'Community Manager topics',
                icon: (
                  <Stack sx={{ ml: 0.3, justifySelf: 'center' }}>
                    <AnnouncementIcon />
                  </Stack>
                ),
              },
            ]}
            description={
              'Users can easily subscribe to Community Manager topics, without creating a Notifi account, managing preferences, or signing their wallet.'
            }
            learnMoreLink="https://docs.notifi.network/docs/alert-subscribe/easy-subscribe"
            handleNextClick={handleCreateEasySubsConfig}
          />
        </Stack>
      </>
    );
  }

  return (
    <>
      {easySubsCardConfigData?.openUserRegistrations?.nodes?.length &&
      cardConfigData.length ? (
        <Typography
          style={{
            color: dusk,
            fontSize: '22px',
            fontWeight: 900,
            lineHeight: '27.28px',
          }}
        >
          Notification Cards
        </Typography>
      ) : null}
      {cardConfigData.length > 0 && (
        <List>
          {cardConfigData?.map((item) => {
            const cardCode = `<NotifiContext
            dappAddress='${data?.userProfile?.tenantInfo?.dappId}'
            env='${env === 'dev' ? 'Development' : 'Production'}'
            walletBlockchain='${item.walletChain.toUpperCase()}'
            walletPublicKey="string"
            signMessage={async (msg: Uint8Array) => msg}
          >
            <NotifiSubscriptionCard cardId='${item.id}' />
          </NotifiContext>`;
            if (item == null || item.id === null || item.id === '') return null;
            const type = getTypeFromVersion(item.version);

            const destinations = Object.keys(item.contactInfo).filter((i) => {
              return item.contactInfo[i as keyof typeof item.contactInfo]
                ?.active;
            });

            const requiredTemplatesType = [
              'Alert History',
              ...destinations,
            ].map((v) => convertDestinationToTemplateType(v));

            const missingTemplateTopic = item.eventTypes.filter((v) => {
              const topicId = (v as any)?.fusionEventId?.value as string;

              if (v.type !== 'fusion') return false;
              const isCMTopic =
                topicsData?.find((e) => e.id === topicId)
                  ?.fusionEventCategory === 'COMMUNITY_MANAGER';
              if (isCMTopic) return false;

              const uploadedTemplates =
                uploadedTemplatesByTopics?.find((e) => e.topicId === topicId)
                  ?.templates ?? [];

              let hasMissingTemplates = false;

              requiredTemplatesType.forEach((type) => {
                if (!hasMissingTemplates && !uploadedTemplates.includes(type))
                  hasMissingTemplates = true;
              });

              return hasMissingTemplates;
            });

            const chain = chains.find(
              (chain) =>
                chain.name?.toLowerCase() === item.walletChain?.toLowerCase(),
            );

            return (
              <div key={item.id}>
                <ListItem
                  onClick={() => {
                    if (!item.id) return;
                    navigate(
                      getCardNavigationUrl(type, item.id) + '?edit_card=true',
                    );
                  }}
                  disablePadding
                  sx={{
                    backgroundColor: light,
                    mb: '23px',
                    borderRadius: '8px',
                    p: '24px 40px',
                    boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
                    cursor: 'pointer',
                    flexDirection: 'column',
                    alignItems: 'start',
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ flex: 1, width: '100%' }}
                    alignItems="start"
                  >
                    <Stack sx={{ gap: '12px' }}>
                      <Stack
                        direction="row"
                        justifyContent="start"
                        alignItems="center"
                      >
                        <Typography sx={{ fontSize: '22px', fontWeight: 900 }}>
                          {item.name}
                        </Typography>
                        <Button
                          sx={{
                            display: 'flex',
                            minWidth: 'unset',
                            width: 'max-content',
                            mt: '-5px',
                            p: 0,
                            ml: '4px',
                          }}
                          onClick={() => {
                            if (!item.id) return;
                            navigate(
                              getCardNavigationUrl(type, item.id) +
                                '?edit_card=true',
                            );
                          }}
                        >
                          <EditIcon
                            sx={{
                              mx: '4px',
                              color: heather,
                              width: '16px',
                              height: '16px',
                            }}
                          />
                        </Button>
                      </Stack>
                      <Stack direction="column" sx={{ mt: '6px' }}>
                        <Grid
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '8px',
                            width: '276px',
                            borderBottom:
                              item.eventTypes.length > 0 ||
                              item.isContactInfoRequired
                                ? `1px solid ${cloudLight}`
                                : 0,
                            paddingBottom: '15px',
                          }}
                        >
                          {item.walletChain.length > 0 ? (
                            <>
                              <img
                                src={chain?.image}
                                alt={chain?.name}
                                width={22}
                                height={22}
                              />
                              <Typography
                                sx={{
                                  color: dusk,
                                  fontSize: '18px',
                                  fontWeight: 800,
                                  lineHeight: '22px',
                                }}
                              >
                                {chain?.name}
                              </Typography>
                            </>
                          ) : (
                            <Typography
                              sx={{
                                color: dusk,
                                fontSize: '18px',
                                fontWeight: 800,
                              }}
                            >
                              No blockchain selected
                            </Typography>
                          )}
                        </Grid>
                        {item.eventTypes.length > 0 ? (
                          <Grid
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignItems: 'center',
                              pt: '16px',
                            }}
                          >
                            {item.eventTypes.map((type) => (
                              <Typography
                                key={type.name}
                                sx={{
                                  paddingRight: '10px',
                                  borderLeft: `2px solid ${heather}`,
                                  paddingLeft: '10px',
                                  color: dusk,
                                  fontSize: '14px',
                                  fontWeight: 800,
                                  lineHeight: '20px',
                                  '&:first-child': {
                                    paddingLeft: 0,
                                    borderLeft: 0,
                                  },
                                }}
                              >
                                {type.name}
                              </Typography>
                            ))}
                          </Grid>
                        ) : null}
                        {item.isContactInfoRequired ? (
                          <Grid
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              pt: '16px',
                              gap: '24px',
                              maxWidth: { xs: '400px', md: '550px' },
                              flexWrap: { xs: 'wrap', md: 'nowrap' },
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Grid
                                sx={{ marginRight: '13px', height: '20px' }}
                              >
                                <IconAlertHistory />
                              </Grid>
                              <Typography
                                sx={{
                                  color: midnight,
                                  fontSize: '16px',
                                  fontWeight: 600,
                                }}
                              >
                                Alert History
                              </Typography>
                            </Box>
                            {destinations.map((d) => {
                              const destination =
                                DESTINATIONS[d as keyof typeof DESTINATIONS];
                              return (
                                <Box
                                  key={destination.name}
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Grid
                                    sx={{ marginRight: '13px', height: '20px' }}
                                  >
                                    {destination?.icon || ''}
                                  </Grid>
                                  <Typography
                                    sx={{
                                      color: midnight,
                                      fontSize: '16px',
                                      fontWeight: 600,
                                    }}
                                  >
                                    {destination?.name || ''}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Grid>
                        ) : null}
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      gap={{ laptop: '16px', xs: '8px' }}
                      flexWrap="wrap"
                      alignItems="center"
                      mt="10px"
                    >
                      {!hasSeenImplementUICardTooltip &&
                      cardConfigData.length === 1 ? (
                        <Box ref={implementUICardTooltipRef}>
                          <CalloutTooltip
                            title="It’s time to implement your new UI Card"
                            subtitle="Drop this UI card into your frontend, and wait for your users to subscribe to alerts."
                            isTooltipOpen={isTooltipOpen}
                            buttonText="Copy Card Code"
                            secondaryButtonText="Docs & Examples"
                            placement={'bottom'}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onClick={async () => {
                              setIsTooltipOpen(false);
                              localStorage.setItem(
                                'hasSeenImplementUICardTooltip',
                                JSON.stringify(true),
                              );
                              setIsCopyCodeModalOpen(true);
                              setCardCode(cardCode);
                              navigator.clipboard.writeText(cardCode);
                            }}
                            onSecondaryButtonClick={async () => {
                              window.open(
                                'https://docs.notifi.network/docs/alert-subscribe/react-card',
                                '_blank',
                              );
                            }}
                          >
                            <Button
                              onClick={(e) => {
                                setIsCopyCodeModalOpen(true);
                                setCardCode(cardCode);
                                e.stopPropagation();
                                navigator.clipboard.writeText(cardCode);
                              }}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                background: eggshell,
                                textTransform: 'none',
                                p: '13px 19px',
                                '&:hover': {
                                  background: cloud,
                                },

                                borderRadius: '4px',
                              }}
                            >
                              <Typography sx={{ fontSize: '14px' }}>
                                Card Code
                              </Typography>
                              <ContentCopy
                                sx={{
                                  height: '16px',
                                  width: '16px',
                                  color: seaglassDarker,
                                  ml: '14px',
                                }}
                              />
                            </Button>
                          </CalloutTooltip>
                        </Box>
                      ) : (
                        <Button
                          onClick={(e) => {
                            setIsCopyCodeModalOpen(true);
                            setCardCode(cardCode);
                            e.stopPropagation();
                            navigator.clipboard.writeText(cardCode);
                          }}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            background: eggshell,
                            textTransform: 'none',
                            p: '13px 19px',
                            '&:hover': {
                              background: cloud,
                            },

                            borderRadius: '4px',
                          }}
                        >
                          <Typography sx={{ fontSize: '14px' }}>
                            Card Code
                          </Typography>
                          <ContentCopy
                            sx={{
                              height: '16px',
                              width: '16px',
                              color: seaglass,
                              ml: '14px',
                            }}
                          />
                        </Button>
                      )}

                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setCardId(item.id ?? '');
                          navigator.clipboard.writeText(item.id ?? '');
                        }}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          background: eggshell,
                          textTransform: 'none',
                          p: '13px 19px',
                          '&:hover': {
                            background: cloud,
                          },

                          borderRadius: '4px',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: '12px',
                            color: dusk,
                            minWidth: '39px',
                          }}
                        >
                          Card ID
                        </Typography>
                        <Typography sx={{ fontSize: '14px', ml: '10px' }}>
                          {item?.id || ''}
                        </Typography>
                        <ContentCopy
                          sx={{
                            height: '16px',
                            width: '16px',
                            color: seaglass,
                            ml: '14px',
                          }}
                        />
                      </Button>
                    </Stack>
                  </Stack>

                  {/* Missing Templates Notification */}
                  {missingTemplateTopic.length ? (
                    <>
                      <Stack
                        sx={{
                          width: '276px',
                          mt: 2,
                          borderTop: `1px solid ${cloudLight}`,
                        }}
                      />
                      <Stack
                        sx={{
                          alignItems: 'start',
                          width: '100%',
                          pt: 3,
                        }}
                      >
                        <Stack flexDirection={'row'} gap={1.5}>
                          <ErrorIcon width={24} height={24} />
                          <Stack gap={0.5}>
                            <Typography
                              sx={{
                                fontSize: '18px',
                                fontWeight: 700,
                                color: midnight,
                              }}
                            >
                              Missing Templates
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 700,
                                color: dusk,
                              }}
                            >
                              The following topics do not have templates defined
                              for all of this card’s supported destinations:
                            </Typography>
                          </Stack>
                        </Stack>

                        {missingTemplateTopic?.map((v, i) => (
                          <Stack
                            key={v.name}
                            flexDirection={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            p={'2px'}
                            pl={2}
                            mt={i === 0 ? 2 : 1.5}
                            width={'449px'}
                            height={'44px'}
                            sx={{
                              backgroundColor: white,
                              borderRadius: '4px',
                              boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: '16px',
                                fontWeight: 700,
                                color: midnight,
                              }}
                            >
                              {v.name}
                            </Typography>

                            <PrimaryButton
                              customSx={{ width: '156px', height: '40px' }}
                              buttonTitle="Add Templates"
                              onClick={() => {
                                setSelectedTopic(
                                  topicsData.find(
                                    (topic) =>
                                      topic.id ===
                                      (v as any)?.fusionEventId?.value,
                                  ),
                                );
                              }}
                            />
                          </Stack>
                        ))}
                      </Stack>
                    </>
                  ) : null}
                </ListItem>
              </div>
            );
          })}
        </List>
      )}

      {easySubsCardConfigData?.openUserRegistrations?.nodes?.length ? (
        <>
          <Typography
            style={{
              color: dusk,
              fontSize: '22px',
              fontWeight: 900,
              lineHeight: '27.28px',
            }}
          >
            Easy Subscribe Configs
          </Typography>
          <List>
            {[...easySubsCardConfigData?.openUserRegistrations?.nodes]
              .reverse()
              .sort((a, b) => {
                const now = new Date();
                const aEnd = a.endTime?.toDateTime;
                const bEnd = b.endTime?.toDateTime;
                if (!aEnd && !bEnd) return 0;
                if (!aEnd) return 0;
                if (!bEnd) return 0;
                const aDate = new Date(aEnd);
                const bDate = new Date(bEnd);
                if (
                  (aDate >= now && bDate >= now) ||
                  (aDate < now && bDate < now)
                ) {
                  return 0;
                }
                if (aDate < now) return 1;
                if (bDate < now) return -1;
                return 0;
              })
              .map((item) => {
                const isCompleted =
                  item.endTime?.toDateTime &&
                  new Date(item.endTime?.toDateTime) < new Date();
                const telegramEndpoint =
                  item.userRegistrationExecutionEndpoints?.find(
                    (endpoint) =>
                      endpoint?.__typename ===
                      'TelegramUserRegistrationExecutionEndpoint',
                  ) as
                    | {
                        __typename: 'TelegramUserRegistrationExecutionEndpoint';
                        groupUserActionUrl: string;
                      }
                    | undefined;

                if (item == null || item.id === null || item.id === '')
                  return null;
                return (
                  <div key={item.id}>
                    <ListItem
                      disablePadding
                      sx={{
                        backgroundColor: light,
                        mb: '23px',
                        borderRadius: '8px',
                        p: '24px 40px',
                        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.05)',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ flex: 1, width: '100%' }}
                        alignItems="start"
                      >
                        <Stack
                          direction="column"
                          sx={{ mt: '6px', width: '100%' }}
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Stack>
                              <Stack
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                              >
                                <Typography
                                  sx={{
                                    fontSize: '22px',
                                    fontWeight: 900,
                                    mb: 1,
                                  }}
                                >
                                  {item.name}
                                </Typography>
                              </Stack>
                              <Grid
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '8px',
                                  borderBottom: `1px solid ${cloudLight}`,
                                  paddingBottom: '15px',
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    lineHeight: '17.35px',
                                    letterSpacing: '-0.19px',
                                  }}
                                >
                                  <span
                                    style={{
                                      color: dusk,
                                      paddingRight: '10px',
                                    }}
                                  >
                                    {item.beginTime?.toDateTime
                                      ? dayjs(item.beginTime?.toDateTime)
                                          .utc()
                                          .format(
                                            `HH:mm [${getUserTimeZone()} on] MMM D, YYYY`,
                                          )
                                      : ''}
                                  </span>
                                  <span
                                    onClick={(e) =>
                                      isCompleted || !item.id
                                        ? undefined
                                        : handleOpenEndPopover(
                                            e,
                                            item?.endTime?.toDateTime,
                                            item?.id,
                                          )
                                    }
                                    style={{
                                      paddingLeft: '10px',
                                      borderLeft: `2px solid ${heather}`,
                                      color:
                                        item.endTime?.toDateTime && isCompleted
                                          ? dusk
                                          : blueglass,
                                      cursor: isCompleted ? 'auto' : 'pointer',
                                    }}
                                  >
                                    {item.endTime?.toDateTime
                                      ? dayjs(item.endTime?.toDateTime)
                                          .utc()
                                          .format(
                                            `HH:mm [${getUserTimeZone()} on] MMM D, YYYY`,
                                          )
                                      : ' + Add End Date'}
                                  </span>
                                </Typography>
                              </Grid>
                            </Stack>
                            <Stack
                              direction="row"
                              gap={{ laptop: '16px', xs: '8px' }}
                              flexWrap="wrap"
                              alignItems="center"
                            >
                              <Button
                                sx={{
                                  display: 'flex',
                                  direction: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  background: eggshell,
                                  textTransform: 'none',
                                  p: '13px 19px',
                                  '&:hover': {
                                    background: cloud,
                                  },
                                  borderRadius: '4px',
                                }}
                                disabled={!!isCompleted}
                                onClick={() => {
                                  if (isOpenUserRegistration(item)) {
                                    setSelectedTopic(item); // Valid OpenUserRegistration
                                  } else if (isCustomFusionEventData(item)) {
                                    setSelectedTopic(item); // Valid CustomFusionEventData
                                  } else {
                                    console.error(
                                      'Invalid type passed to setSelectedTopic',
                                    );
                                  }
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    color: midnight,
                                  }}
                                >
                                  Templates
                                </Typography>
                                <EditIcon
                                  sx={{
                                    color: blueglass,
                                    fontSize: '16px',
                                    p: 0,
                                    cursor: 'pointer',
                                    marginLeft: '6px',
                                    fontWeight: 'bold',
                                  }}
                                />
                              </Button>
                              <Button
                                sx={{
                                  display: 'flex',
                                  direction: 'row',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  background: eggshell,
                                  textTransform: 'none',
                                  p: '13px 19px',
                                  '&:hover': {
                                    background: cloud,
                                  },
                                  borderRadius: '4px',
                                }}
                                disabled={!!isCompleted}
                                onClick={() =>
                                  window.open(
                                    'https://docs.notifi.network/docs/alert-subscribe/easy-subscribe',
                                    '_blank',
                                  )
                                }
                              >
                                <Typography
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: 700,
                                    color: midnight,
                                  }}
                                >
                                  {isCompleted
                                    ? 'Completed'
                                    : 'Implementation Guide'}
                                </Typography>
                                {!isCompleted && (
                                  <OpenInNewIcon
                                    sx={{
                                      color: blueglass,
                                      fontSize: '16px',
                                      p: 0,
                                      cursor: 'pointer',
                                      marginLeft: '6px',
                                      fontWeight: 'bold',
                                    }}
                                  />
                                )}
                              </Button>
                            </Stack>
                          </Stack>
                          <Grid
                            sx={{
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignItems: 'center',
                              pt: '16px',
                            }}
                          >
                            {item.fusionEventIds?.map((fusionEvent) => {
                              const fusionEventName =
                                fusionEventsData?.fusionEvents?.nodes?.find(
                                  (fusionEventsData) =>
                                    fusionEventsData.id === fusionEvent,
                                );
                              return (
                                <Typography
                                  key={fusionEvent}
                                  sx={{
                                    paddingRight: '10px',
                                    borderLeft: `2px solid ${heather}`,
                                    paddingLeft: '10px',
                                    color: dusk,
                                    fontSize: '14px',
                                    fontWeight: 800,
                                    lineHeight: '20px',
                                    '&:first-child': {
                                      paddingLeft: 0,
                                      borderLeft: 0,
                                    },
                                  }}
                                >
                                  {fusionEventName?.name}
                                </Typography>
                              );
                            })}
                          </Grid>

                          {!isCompleted && (
                            <Grid
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                pt: '16px',
                                gap: '24px',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                  flexWrap: 'wrap',
                                }}
                              >
                                {item.userRegistrationExecutionEndpoints?.map(
                                  (Endpoint, index) => {
                                    // Handle different endpoint types
                                    const postUrl =
                                      Endpoint?.__typename ===
                                      'EmailUserRegistrationExecutionEndpoint'
                                        ? Endpoint?.postUrl
                                        : Endpoint?.__typename ===
                                          'TelegramUserRegistrationExecutionEndpoint'
                                        ? Endpoint?.userActionUrl
                                        : '';

                                    // Set the label based on the endpoint type
                                    const endpointLabel =
                                      Endpoint?.__typename ===
                                      'EmailUserRegistrationExecutionEndpoint'
                                        ? 'Email Endpoint'
                                        : Endpoint?.__typename ===
                                          'TelegramUserRegistrationExecutionEndpoint'
                                        ? 'Telegram Endpoint'
                                        : '';

                                    return (
                                      <Button
                                        key={index}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setCardURL(postUrl ?? '');
                                          navigator.clipboard.writeText(
                                            postUrl ?? 'No URL available',
                                          );
                                        }}
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          background: eggshell,
                                          textTransform: 'none',
                                          p: '13px 19px',
                                          '&:hover': {
                                            background: cloud,
                                          },
                                          borderRadius: '4px',
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color: midnight,
                                            fontSize: '16px',
                                            fontWeight: 600,
                                          }}
                                        >
                                          {endpointLabel}
                                        </Typography>
                                        <ContentCopy
                                          sx={{
                                            height: '16px',
                                            width: '16px',
                                            color: seaglass,
                                            ml: '14px',
                                          }}
                                        />
                                      </Button>
                                    );
                                  },
                                )}

                                {item.userRegistrationExecutionEndpoints?.some(
                                  (endpoint) => {
                                    return (
                                      endpoint?.__typename ===
                                        'TelegramUserRegistrationExecutionEndpoint' &&
                                      endpoint.groupUserActionUrl
                                    );
                                  },
                                ) && (
                                  <Stack
                                    direction={'row'}
                                    onClick={() =>
                                      setIsAddTelegramToGroupDialogOpen({
                                        open: true,
                                        link: telegramEndpoint?.groupUserActionUrl,
                                      })
                                    }
                                    sx={{
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      textTransform: 'none',
                                      borderColor: newBlueglass,
                                      p: '9px 19px',
                                      '&:hover': {
                                        background: 'cloud',
                                      },
                                      borderRadius: '4px',
                                      border: `1px solid ${newBlueglass}`,
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <TelegramIcon
                                      style={{ marginRight: '10px' }}
                                    />
                                    <Typography
                                      sx={{
                                        color: newBlueglass,
                                        fontSize: '16px',
                                        fontWeight: 600,
                                      }}
                                    >
                                      Add Alerts to Telegram Groups
                                    </Typography>
                                  </Stack>
                                )}
                              </Box>
                            </Grid>
                          )}
                        </Stack>
                      </Stack>
                    </ListItem>
                  </div>
                );
              })}
          </List>
        </>
      ) : null}

      <ConfirmDialog
        title="Card Code"
        subText="Drop this UI card code into your frontend, and wait for your users to subscribe to alerts."
        open={isCopyCodeModalOpen}
        handleClose={() => setIsCopyCodeModalOpen(false)}
        handleConfirm={() => setIsCopyCodeModalOpen(false)}
        ctaTitle="Got it"
        sx={{ width: '600px' }}
        hideSecondaryAction
      >
        <TextField
          multiline
          rows={11}
          disabled
          value={cardCode}
          sx={{
            mt: 2,
            mb: 1,
            '& textarea': {
              backgroundColor: eggshell,
              borderRadius: '4px',
              p: '15px 20px',
              fontFamily: 'Rota',
              lineHeight: '20px',
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: seaglassDarker,
                borderRadius: '10px',
              },
            },
            '& .MuiInputBase-root': {
              p: 0,
              border: 'none',
            },
            width: '100%',
          }}
        />
      </ConfirmDialog>

      <DefineTemplatesDialog
        topic={selectedTopic}
        handleClose={handleCloseTemplateModal}
        open={!!selectedTopic}
      />

      <ConfirmDialog
        title="Card ID is in your clipboard"
        open={Boolean(cardId)}
        handleClose={() => {
          setCardId('');
        }}
        handleConfirm={() => {
          setCardId('');
        }}
        ctaTitle="Got it"
        sx={{ width: '600px' }}
        hideSecondaryAction
      >
        <TextField
          multiline
          disabled
          value={cardId}
          sx={{
            mt: 2,
            mb: 1,
            '& textarea': {
              backgroundColor: eggshell,
              borderRadius: '4px',
              p: '15px 20px',
              fontFamily: 'Rota',
              textAlign: 'center',
              lineHeight: '20px',
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: seaglassDarker,
                borderRadius: '10px',
              },
            },
            '& .MuiInputBase-root': {
              p: 0,
              border: 'none',
            },
            width: '100%',
          }}
        />
      </ConfirmDialog>
      <ConfirmDialog
        title="Card URL is in your clipboard"
        open={Boolean(cardURL)}
        handleClose={() => {
          setCardURL('');
        }}
        handleConfirm={() => {
          setCardURL('');
        }}
        ctaTitle="Got it"
        sx={{ width: '600px' }}
        hideSecondaryAction
      >
        <TextField
          multiline
          disabled
          value={cardURL}
          sx={{
            mt: 2,
            mb: 1,
            '& textarea': {
              backgroundColor: eggshell,
              borderRadius: '4px',
              p: '15px 20px',
              fontFamily: 'Rota',
              textAlign: 'center',
              lineHeight: '20px',
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: seaglassDarker,
                borderRadius: '10px',
              },
            },
            '& .MuiInputBase-root': {
              p: 0,
              border: 'none',
            },
            width: '100%',
          }}
        />
      </ConfirmDialog>
      <Popover
        id="end-date-picker"
        open={isEndPopoverOpen.isVisible}
        anchorEl={anchorElEnd}
        onClose={handleCloseEndPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            borderRadius: 0,
            boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <CustomDatePicker
          selectedDate={endDate}
          onChange={(dateTime) => {
            setEndDate(dateTime);
          }}
          onSubmit={updateEasySubsUICard}
        />
      </Popover>
      <AddTelegramToGroupDialog
        open={isAddTelegramToGroupDialogOpen.open}
        link={isAddTelegramToGroupDialogOpen.link}
        handleClose={() =>
          setIsAddTelegramToGroupDialogOpen({ open: false, link: '' })
        }
      />
    </>
  );
};

export default UIConfigTab;
